import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Banking App Wireframe | Banking App Wireframe Template | Uizard"
    canonical="/templates/mobile-app-templates/banking-mobile-app-wireframe/"
    category="Tablet Templates"
    url="/templates/tablet-templates/banking-tablet-app-wireframe/"
    metaDescription="Got a great idea for a banking app design that you can't wait to bring to life? Our banking app wireframe is here to fast-track your design flow."
    description="
    h2:Use our banking app wireframe in low or medium fidelity
    <br/>
    Got a great idea for a banking app design that you can't wait to bring to life? Our banking app wireframe is here to fast-track your design flow. As with all our wireframes, our banking app wireframe comes with all the core screens your design could need as well as built-in user flows, demonstrating how users would click through your final product. 
    <br/>
    h3:Wireframing made easy with our medium fidelity banking app wireframe for tablet
    <br/>
    Our banking app wireframe was designed to save you time. Whether you are a founder or a project manager, our wireframes are here to fast-track your design flow. And don't forget, Uizard is a full spectrum <a:https://uizard.io/wireframing/>UX wireframing tool</a>, meaning you can transform our medium fidelity wireframes into low fidelity with the click of a button using Wireframe Mode.
    <br/>
    h3:Collaborate fast and customize with ease
    <br/>
    Working as part of a team? Uizard's Smart Collaboration features enable you to reinvent how you work together on a project. Invite teammates, share previews, and collaborate in real-time. You can invite external stakeholders to expedite the sign-off process for your <a:https://uizard.io/templates/mobile-app-templates/mobile-app-wireframe/>app wireframe</a>.
    "
    pages={[
      "Core landing page in medium fidelity with clickable links",
      "Secondary screens with core UX features mapped out",
      "A comprehensive set of UI components to demonstrate the full scope of your app wireframe",
      "NEW: Convert the design to a low-fidelity wireframe using Uizard's Wireframe Mode",
    ]}
    projectCode="j4Bbr9wlJpTE3a9O5WYY"
    img1={data.image1.childImageSharp}
    img1alt="banking tablet app wireframe cover"
    img2={data.image2.childImageSharp}
    img2alt="banking tablet app wireframe medium fidelity account screen"
    img3={data.image3.childImageSharp}
    img3alt="banking tablet app wireframe medium fidelity summary"
    img4={data.image4.childImageSharp}
    img4alt="banking tablet app wireframe low fidelity account screen"
    img5={data.image5.childImageSharp}
    img5alt="banking tablet app wireframe low fidelity summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/banking-tablet-app-wireframe/wireframe-online-banking-app-tablet-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/banking-tablet-app-wireframe/wireframe-online-banking-app-tablet-transfer.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/banking-tablet-app-wireframe/wireframe-online-banking-app-tablet-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/banking-tablet-app-wireframe/lo-fi-mobile-banking-tablet-screen.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/banking-tablet-app-wireframe/lo-fi-mobile-banking-tablet-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
